"use client";

import { useEffect } from "react";
import { useSession } from "next-auth/react";

import { useCheckoutStore } from "~/hooks";

/**
 * This component is used as an edge-case to be able to run client side logic in a server side page
 */

export default function StoreCleaner() {
  const session = useSession();
  const { reset } = useCheckoutStore();

  useEffect(() => {
    if (!session.data) {
      reset();
    }
  }, [session.data, reset]);

  return null;
}
