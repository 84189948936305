"use client";

import { useEffect } from "react";
import { useLocale, useTranslations } from "next-intl";

import { getLocaleFieldValue } from "~/helpers/i18n";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

export default function DynamicTitleSetter() {
  const t = useTranslations();
  const locale = useLocale();
  const { name } = useStoreInfo() || {};

  const storeName = getLocaleFieldValue(name, locale);

  useEffect(() => {
    if (storeName) {
      document.title = t("layout.storeNameTitle", { storeName });
    }
  }, [storeName, t]);

  return null;
}
