"use client";

import type { ReactNode } from "react";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";

type Props = {
  children: ReactNode;
  session: Session | null;
};

export default function NextAuthSessionClientProvider({
  children,
  session,
}: Props) {
  return (
    <SessionProvider
      refetchOnWindowFocus={false}
      refetchWhenOffline={false}
      session={session}
    >
      {children}
    </SessionProvider>
  );
}
