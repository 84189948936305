"use client";

import { useTranslations } from "next-intl";

import { FullscreenErrorMessage } from "~/components";

export default function MaintenanceMode() {
  const t = useTranslations();

  return (
    <FullscreenErrorMessage
      title={t("layout.maintenanceMode.title")}
      description={t("layout.maintenanceMode.description")}
    />
  );
}
