"use client";

import { useEffect } from "react";
import hotjar from "@hotjar/browser";

import { useNavigationEvent } from "~/hooks";

interface Props {
  siteId: number;
  version: number;
  userId: string;
}

export default function Hotjar({ siteId, version, userId }: Props) {
  useNavigationEvent({
    onChange: (url) => {
      if (hotjar.isReady()) {
        hotjar.stateChange(url);
      }
    },
  });

  useEffect(() => {
    hotjar.init(siteId, version);
  }, [siteId, version]);

  useEffect(() => {
    if (hotjar.isReady()) {
      hotjar.identify(userId, {});
    }
  }, [userId]);

  return null;
}
