import Script from "next/script";

import { type StoreInfo } from "~/types";

interface Props {
  appcuesId: string;
  storeInfo: StoreInfo;
}

export default function Appcues({ appcuesId, storeInfo }: Readonly<Props>) {
  const {
    email,
    userConfig: { roles },
    tracking: {
      subscriptionCategory,
      subscriptionStartDate,
      subscriptionEndDate,
    },
  } = storeInfo;

  const data = {
    hasPos: true,
    posLastSeen: new Date().toISOString(),
    posPlan: subscriptionCategory ?? null,
    posSubscriptionStartedAt: subscriptionStartDate ?? null,
    posSubscriptionExpiry: subscriptionEndDate ?? null,
    posUserPermissions: roles,
  };

  // TODO: Add missing data for posPlanType and posPlanExpiry once it's available
  const identifyData = `
  const appcuesIdentify = document.getElementById("appcues-script");
  appcuesIdentify?.addEventListener("load", () => {
    window.Appcues.identify(
      "${email}",
      ${JSON.stringify(data)} 
    );
  });
`;

  const urlDetectionData = `
  const appcuesUrlDetection = document.getElementById("appcues-script");
  appcuesUrlDetection?.addEventListener("load", () => {
    window.AppcuesSettings = {
      enableURLDetection: true,
    }
  });
`;

  return (
    <>
      <Script src={`//fast.appcues.com/${appcuesId}.js`} id="appcues-script" />
      <Script
        id="appcues-url-detection"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: urlDetectionData,
        }}
      />
      <Script
        id="appcues-identify"
        dangerouslySetInnerHTML={{
          __html: identifyData,
        }}
      />
    </>
  );
}
