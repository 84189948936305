"use client";

import { useEffect, type PropsWithChildren } from "react";
import Cookies from "js-cookie";
import { useLocale } from "next-intl";

import { CookieName } from "@offline/constants/enums";
import { SUPPORTED_LOCALES } from "@offline/constants/i18n";

import { usePathname, useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

const isLocaleSupported = (locale: string | undefined) => {
  return locale && SUPPORTED_LOCALES.includes(locale);
};

export default function AdminLanguageMatcher({ children }: PropsWithChildren) {
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();
  const { adminLanguage, country } = useStoreInfo() || {};

  const adminLanguageWithCountry = `${adminLanguage}-${country}`;

  const isAdminLocaleSupported = isLocaleSupported(adminLanguage);
  const isAdminCountryLocaleSupported = isLocaleSupported(
    adminLanguageWithCountry,
  );

  const preferredAdminLocale = isAdminCountryLocaleSupported
    ? adminLanguageWithCountry
    : adminLanguage;
  const isLocaleMismatch = locale !== preferredAdminLocale;

  const shouldMatchAdminLocale =
    (isAdminLocaleSupported || isAdminCountryLocaleSupported) &&
    isLocaleMismatch;

  const hasAlreadyMatchedLocale = Cookies.get(CookieName.MATCHED_LOCALE);
  const shouldRedirect = shouldMatchAdminLocale && !hasAlreadyMatchedLocale;

  useEffect(() => {
    if (shouldRedirect) {
      router.replace(pathname, {
        locale: preferredAdminLocale,
      });
      Cookies.set(CookieName.MATCHED_LOCALE, "true");
    }
  }, [preferredAdminLocale, pathname, router, shouldRedirect]);

  return shouldRedirect ? null : children;
}
