"use client";

import { useEffect } from "react";
import { isNil, omitBy } from "lodash";

interface Props {
  user: {
    id: string | null;
    name?: string | null;
    email?: string | null;
    storeId?: string | null;
  };
}

export default function Dynatrace({ user }: Props) {
  useEffect(() => {
    /**
     * `window.dtrum` is injected automatically in k8s container
     * It won't be present in local environment, that's why this component can always be rendered
     **/

    if (!window.dtrum) {
      return;
    }

    const { id, name, email, storeId } = user;

    if (storeId) {
      window.dtrum.identifyUser(storeId);
    }

    const userStringProperties = omitBy(
      {
        name,
        email,
        user_id: id,
        store_id: storeId,
      },
      isNil,
    ) as Record<string, string>;

    window.dtrum.sendSessionProperties(
      undefined,
      undefined,
      userStringProperties,
    );

    // Enable session replay but honoring cost control
    window.dtrum.enableSessionReplay(false);
  }, [user]);

  return null;
}
