"use client";

import { useSession } from "next-auth/react";

import { env } from "~/env.mjs";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import Amplitude from "./Amplitude";
import Appcues from "./Appcues";
import Dynatrace from "./Dynatrace";
import Hotjar from "./Hotjar";
import Satismeter from "./Satismeter";

const {
  NEXT_PUBLIC_AMPLITUDE_API_KEY,
  NEXT_PUBLIC_APPCUES_ID,
  NEXT_PUBLIC_HOTJAR_SITE_ID,
  NEXT_PUBLIC_HOTJAR_VERSION,
  NEXT_PUBLIC_SATISMETER_WRITE_KEY,
} = env;

const APPCUES_ENABLED = !!NEXT_PUBLIC_APPCUES_ID;
const AMPLITUDE_ENABLED = !!NEXT_PUBLIC_AMPLITUDE_API_KEY;
const HOTJAR_ENABLED =
  !!NEXT_PUBLIC_HOTJAR_SITE_ID && !!NEXT_PUBLIC_HOTJAR_VERSION;
const SATISMETER_ENABLED = !!NEXT_PUBLIC_SATISMETER_WRITE_KEY;

export default function ExternalServices() {
  const storeInfo = useStoreInfo();
  const session = useSession();

  if (session.status !== "authenticated" || !session.data || !storeInfo) {
    return null;
  }

  const { user } = session.data;

  return (
    <>
      <Dynatrace user={user} />

      {HOTJAR_ENABLED && (
        <Hotjar
          siteId={NEXT_PUBLIC_HOTJAR_SITE_ID}
          version={NEXT_PUBLIC_HOTJAR_VERSION}
          userId={user.id}
        />
      )}

      {APPCUES_ENABLED && (
        <Appcues appcuesId={NEXT_PUBLIC_APPCUES_ID} storeInfo={storeInfo} />
      )}

      {SATISMETER_ENABLED && (
        <Satismeter
          writeKey={NEXT_PUBLIC_SATISMETER_WRITE_KEY}
          storeInfo={storeInfo}
        />
      )}

      {AMPLITUDE_ENABLED && (
        <Amplitude
          apiKey={NEXT_PUBLIC_AMPLITUDE_API_KEY}
          storeInfo={storeInfo}
        />
      )}
    </>
  );
}
