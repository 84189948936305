"use client";

import Script from "next/script";
import { useLocale } from "next-intl";

import { getLocaleFieldValue } from "~/helpers/i18n";
import { type StoreInfo } from "~/types";

interface Props {
  writeKey: string;
  storeInfo: StoreInfo;
}

export default function Satismeter({ writeKey, storeInfo }: Readonly<Props>) {
  const locale = useLocale();
  const {
    id,
    country,
    name,
    email,
    type,
    createdAt,
    tracking: { subscriptionCategory },
  } = storeInfo;
  const storeName = getLocaleFieldValue(name, locale);

  const script = `
(function() { window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/js";parent.appendChild(script);})();

satismeter({
  writeKey: "${writeKey}",
  userId: "${id}",
  traits: {
    name: "${storeName}",
    country: "${country}",
    email: "${email}",
    vertical: "${type}",
    ${subscriptionCategory ? `plan: "${subscriptionCategory}",` : ""}
    created_at: "${createdAt}"
  }
});
`;

  return (
    <Script
      id="satismeter-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: script,
      }}
    />
  );
}
