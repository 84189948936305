"use client";

import { useTranslations } from "next-intl";

import { Text, Title } from "~/components";
import SignInButton from "./SignInButton";

export default function NotAuthenticated() {
  const t = useTranslations("page.home");

  return (
    <>
      <Title
        as="h1"
        textAlign="center"
        fontSize="10"
        lineHeight="12"
        color="primary-textLow"
      >
        {t("notAuthenticated.title")}
      </Title>

      <Text
        textAlign="center"
        color="primary-textLow"
        fontSize="highlight"
        lineHeight="highlight"
      >
        {t("notAuthenticated.subtitle")}
      </Text>

      <SignInButton />
    </>
  );
}
